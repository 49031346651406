import React from 'react';

 //Main Index
const Main = React.lazy(() => import('./pages/Home/indexMain'));
const About = React.lazy(() => import('./pages/About/index'));
const Mission = React.lazy(() => import('./pages/Mission/index'));
const Team = React.lazy(() => import('./pages/Team/index'));
const Ecosystem = React.lazy(() => import('./pages/Ecosystem/index'));
const Pre = React.lazy(() => import('./pages/Pre/index'));
const Hpt = React.lazy(() => import('./pages/Hpt/index'));
const Engine = React.lazy(() => import('./pages/Engine/index'));
const Keywords = React.lazy(() => import('./pages/Keywords/index'));
const Packages = React.lazy(() => import('./pages/Packages/index'));
const SearchApi = React.lazy(() => import('./pages/SearchApi/index'));
const Nodes = React.lazy(() => import('./pages/Nodes/index'));
const Roadmap = React.lazy(() => import('./pages/Roadmap/index'));
const Collaborate = React.lazy(() => import('./pages/Collaborate/index'));
const Exchanges = React.lazy(() => import('./pages/Exchanges/index'));
const Wallets = React.lazy(() => import('./pages/Wallets/index'));
const ErrorPage = React.lazy(() => import('./pages/Error/index'));
const Redirect = (url) => {
    window.location.href = url;
    return null;
}

const routes = [
    //Index Main
    { path: '/', component: Main },
    { path: '/about', component: About },
    { path: '/mission', component: Mission },
    { path: '/team', component: Team },
    { path: '/ecosystem', component: Ecosystem },
    { path: '/pre', component: Pre },
    { path: '/advertise', component: Hpt },
    { path: '/engine', component: Engine },
    { path: '/keywords', component: Keywords },
    { path: '/packages', component: Packages },
    { path: '/searchapi', component: SearchApi },
    { path: '/nodes', component: Nodes },
    { path: '/roadmap', component: Roadmap },
    { path: '/collaborate', component: Collaborate },
    { path: '/exchanges', component: Exchanges },
    { path: '/wallets', component: Wallets },
    { path: '/hpt', isWithoutLayout : true, component: () => Redirect('https://form.typeform.com/to/FAN5j9x0') },
    { path: '/pta', isWithoutLayout : true, component: () => Redirect('https://form.typeform.com/to/FAN5j9x0') },
    { component: ErrorPage, isWithoutLayout : true, exact : false },
];

export default routes;